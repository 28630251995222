import _ from 'lodash';
import { useRoutes } from 'react-router-dom';
import { routes } from '@/utils/routes';
import { usePageView } from '@/utils/GA';
import { useAuth } from '../Auth';
import Loading from '../Loading';

const AuthRoutes = () => {
  usePageView();
  const { currentUser, isFetching } = useAuth();
  const isLogin = !_.isNil(currentUser);
  const main = useRoutes(routes(currentUser));

  if (isFetching) return <Loading />;
  if (!isLogin) {
    return <Loading />; // 有 sctid 表示有登入但 api 尚未返回，沒取到 user 先返回 loading
  }

  return <>{main}</>;
};

export default AuthRoutes;
