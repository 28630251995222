import _ from 'lodash';
import {
  getSuccessTokenId,
  saveSuccessTokenId,
  saveSuccessTokenData,
  removeAuthTokens,
  saveEnSctId,
} from '@/utils/token';
import SocketUtil from '../../../utils/socketUtil';
import { authenticated, updateCurrentUser, appInitFetching } from '@/container/HomePage/slice/authSlice';
import SSOAuthentication from '../../../models/ssoAuthentication';

const name = 'app_init';

export const appInit = ({ ST, navigate }) => async dispatch => {
  await dispatch(appInitFetching({ [name]: true }));
  const SCT_ID = await getSuccessTokenId();

  if (_.isEmpty(SCT_ID)) {
    if (_.isEmpty(ST)) {
      dispatch(authenticated(true));
      dispatch(updateCurrentUser({}));
    } else {
      const { SCT_ID, exp = 30, err } = await SSOAuthentication.getSctId(ST);
      if (err) {
        console.log('Verify ST Failed: ', err);
        await removeAuthTokens(SCT_ID);
        dispatch(authenticated(true));
        dispatch(updateCurrentUser({}));
      }
      await saveSuccessTokenId(SCT_ID, exp);
      navigate(window.location.pathname);
    }
  } else {
    if (!_.isEmpty(ST)) {
      const { SCT_ID, exp = 30, err } = await SSOAuthentication.getSctId(ST);
      if (err) {
        console.log('Verify ST Failed: ', err);
        await removeAuthTokens(SCT_ID);
        dispatch(authenticated(true));
        dispatch(updateCurrentUser({}));
      }
      await saveSuccessTokenId(SCT_ID, exp);
      navigate(window.location.pathname);
    } else {
      const { enSctId, uniNum, user, err } = await SSOAuthentication.getUser(SCT_ID);
      if (err) {
        console.log('Verify SCT Failed: ', err);
        await removeAuthTokens(SCT_ID);
        dispatch(authenticated(true));
        dispatch(updateCurrentUser({}));
      }
      if (!_.isEmpty(user)) {
        await saveSuccessTokenData(SCT_ID, uniNum, enSctId);
        await saveEnSctId(enSctId);
        await SocketUtil.connectCasServer({ user_id: user?._id, enSctId })
          .then(() => SocketUtil.connectSelfServer())
          .then(() => {
            dispatch(authenticated(true));
            dispatch(updateCurrentUser(user));
          });
      } else {
        console.log('FindUserBySctId Failed'); // 你已經登出
        await removeAuthTokens(SCT_ID);
        dispatch(authenticated(true));
        dispatch(updateCurrentUser({}));
      }
    }
  }
  dispatch(appInitFetching({ [name]: false }));
};
