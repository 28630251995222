import _ from 'lodash';
import { io } from 'socket.io-client';
import { api_url, cas_api_url, shareUrl } from '@/config';
import {
  removeEnSctId,
  removeSuccessTokenDataWithId,
  removeSuccessTokenId,
  getEnSctId,
  removeAuthTokens,
  getSuccessTokenId,
} from './token';
import SSORedirectUtil from '../component/Auth/utils/ssoRedirectUtil';
import { appInitFetching } from '@/container/HomePage/slice/authSlice';
import SSOAuthentication from '../models/ssoAuthentication';
import { getSToken, getTokenId, removeSToken } from './shareToken';
import store from '../store';
import { addToCommentGroupList, addToComment, updateGroupData } from '@/container/SharedPage/slice/commentSlice';
import { loginInvalid, setPasswordChanged } from '@/container/SharedPage/slice/authSlice';
import { updateLabCase, updateSharedCase } from '@/container/SharedPage/slice/caseSlice';

const socketOptions = {
  upgrade: true,
  transports: ['websocket'],
  withCredentials: true,
  extraHeaders: {},
};

export const LOGIN_ROOM = 'user_login';
export const LOGOUT_ROOM = 'user_logout';
export const LOGOUT_ALL = 'all_user_logout';

export default class SocketUtil {
  constructor() {
    this.is_connected = false;
    this.cas_socket = undefined;
    this.is_self_connected = false;
    this.self_server_socket = undefined;
    this.is_connected_share_socket = false;
    this.share_socket = undefined;
  }

  static connectCasServer = async ({ user_id, enSctId }) => {
    if (!this.is_connected) {
      const socket = io(cas_api_url, socketOptions);
      socket.on('connect', () => {
        console.log('CAS socket connect!');
        this.is_connected = true;
        this.cas_socket = socket;
        if (!_.isEmpty(user_id)) {
          this.cas_socket.emit(LOGIN_ROOM, user_id, enSctId);
        }
      });
      socket.on('disconnect', () => {
        console.log('CAS socket disconnect!');
        this.is_connected = false;
        this.cas_socket = null;
      });
      socket.on('connect_error', err => {
        console.log('CAS socket connect error: ', err.message);
      });
      socket.on(LOGOUT_ALL, async (uniNum, enSctId) => {
        await removeSuccessTokenId();
        await removeSuccessTokenDataWithId(uniNum);
        await removeEnSctId();
        await SSORedirectUtil.redirectCasLogoutPath(enSctId, uniNum);
      });
    }
    return Promise.resolve();
  };

  static connectSelfServer = async () => {
    if (!this.is_connected) {
      const socketWithSelfServer = io(api_url, socketOptions);
      socketWithSelfServer.on('connect', () => {
        console.log('Self socket connect!');
        this.is_self_connected = true;
        this.self_server_socket = socketWithSelfServer;
      });
      socketWithSelfServer.on('disconnect', () => {
        console.log('Self socket disconnect!');
        this.is_self_connected = false;
        this.self_server_socket = null;
      });
      socketWithSelfServer.on('connect_error', err => {
        console.log('Self socket connect error: ', err.message);
      });
    }
    return Promise.resolve();
  };

  static connectShareServer = async () => {
    if (!this.is_connected_share_socket) {
      const share = io(shareUrl, {
        ...socketOptions,
        auth: cb => {
          cb({
            token: getSToken(),
          });
        },
      });
      share.on('connect', () => {
        console.log('share socket connect!');
        // join room
        let id = getTokenId(getSToken());
        share.emit('share:login', id);
        this.share_socket = share;
      });

      // handle middleware error
      share.on('connect_error', err => {
        console.log('middleware error', err.message);
        removeSToken();
        store.dispatch(loginInvalid());
        this.share_socket.disconnect(true);
      });
      share.on('disconnect', err => {
        console.log('Disconnected from server', err);
      });

      // comment
      share.on('broadcast-new-comment-group', comment => {
        store.dispatch(addToCommentGroupList(comment));
      });
      share.on('broadcast-update-comment-group', comment => {
        store.dispatch(updateGroupData(comment));
      });
      share.on('broadcast-new-comment', comment => {
        store.dispatch(addToComment(comment));
      });

      // detect case update
      share.on('case-has-updated', data => {
        store.dispatch(updateLabCase(data));
      });
      share.on('shared-case-has-updated', data => {
        store.dispatch(updateSharedCase(data));
      });
      // detect password changed
      share.on('share:password-changed', () => {
        store.dispatch(setPasswordChanged());
      });
    }
    return Promise.resolve();
  };

  static casSocketEmit = async (room_name, uniNum, enSctId) => {
    if (this.cas_socket) {
      await this.cas_socket.emit(room_name, uniNum, enSctId);
    }
  };

  static casSocketEmitLogout = async (room_name = LOGOUT_ROOM, user_id, uniNum, enSctId) => {
    if (this.cas_socket) {
      await this.cas_socket.emit(room_name, user_id, uniNum, enSctId);
    }
    await SSORedirectUtil.redirectCasLogoutPath(enSctId, uniNum);
  };

  static onLogoutAll = async ({ user_id, uniNum }) => {
    const enSctId = await getEnSctId();
    const SCT_ID = await getSuccessTokenId();
    await removeAuthTokens(SCT_ID);
    await SocketUtil.casSocketEmitLogout(LOGOUT_ROOM, user_id, uniNum, enSctId);
  };

  static fetchStd = getStd => async dispatch => {
    try {
      if (!_.isEmpty(JSON.parse(getStd))) {
        const std = JSON.parse(getStd);
        const name = 'fetch_std';
        await dispatch(appInitFetching({ [name]: true }));
        const SCT_ID = await getSuccessTokenId();
        const { errorCode, msg, users } = await SSOAuthentication.postStd(SCT_ID, std);
        if (!_.isEmpty(users)) {
          // console.log('std = ', users); // 曾經登入過後的
        } else {
          console.log('FetchStd Failed or No user get: ', errorCode, ' ', msg);
        }
      }
    } catch (e) {
      // is std Empty
    }
  };

  static disconnectShareSocket = () => {
    this.share_socket && this.share_socket.emit('share:logout');
    this.share_socket && this.share_socket.disconnect(true);
  };
}
