import React, { useEffect, useState } from 'react';
import Authentication from './Account';
import { NavLink } from 'react-router-dom';
import { Tooltip, Drawer, Button } from 'antd';
import styled, { createGlobalStyle } from 'styled-components';
import { useIntl } from 'react-intl';
import { saveSuccessTokenData } from '@/utils/token';
import logo from '../../images/group-copy.svg';
import setting from '../../images/1-2-icon-his-icons-settings-2.svg';
import { useDevice } from '@/utils/hooks/useDevice';
import { ReactComponent as MenuIcon } from '../../images/icon-menu.svg';
import { ReactComponent as CloseIcon } from '../../images/icons-close.svg';
import { ReactComponent as HomeIcon } from '../../images/1-2-icon-feather-icons-home.svg';
import { ReactComponent as SettingIcon } from '../../images/1-2-icon-feather-icons-settings.svg';
import { settingMenu } from '@/container/SettingPage/constant';
import PanelMenu from '../Panel/PanelMenu';
import SSORedirectUtil from '../Auth/utils/ssoRedirectUtil';
import { wsUrl } from '@/config';
import SocketUtil from '../../utils/socketUtil';

//#region
const NavbarWrapper = styled.div`
  width: 100%;
  height: 64px;
  box-shadow: 0 4px 20px 0 rgba(39, 55, 89, 0.1);
  background-color: var(--default-500-light);
  border-radius: 0 0 8px 8px;
  padding: 20px 0;
  @media (min-width: 960px) {
    border-radius: unset;
  }
`;
const HomeIconLink = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const StyledNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 18px;
  &:hover {
    ${props => props.$setting && 'background-color: var(--color-fill-1-primary-100);'}
  }
`;
const NavbarDetail = styled.div`
  max-width: 1440px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  margin: 0 auto;
  @media (min-width: 960px) {
    padding: 0 32px;
  }
`;
const NavSettingAndAuth = styled.div`
  display: flex;
`;
const MenuButton = styled(Button)`
  border: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  box-shadow: none;
`;
const MobileNavStyle = createGlobalStyle`
  .nav__drawer {
    top: 64px;
    &.ant-drawer-open .ant-drawer-content-wrapper {
      box-shadow: unset;
    }
    .ant-menu-item {
      margin: 0;
    }
    .nav__menu {
      border-right: 0;
      .second-subMenu {
      & > div.ant-menu-submenu-title {
        font-size: 1em;
        color: var(--default-500-dark);
        &:hover {
          color: var(--default-500-primary);
        }
      }
      & > ul.ant-menu-inline {
        background-color: var(--basic-fill-02-200);
      }
    }
    }
  }
`;
//#endregion

const Navbar = ({ ws, member, currentUser }) => {
  const { formatMessage: f } = useIntl();
  const { isTabletAndMobile, DesktopOrLaptop } = useDevice();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    saveSuccessTokenData();
  });

  const drawerHandler = () => setVisible(false);

  const mobileMenu = (f, clinic) => [
    {
      key: 'home',
      icon: <HomeIcon />,
      name: f({ id: 'nav.drawer.home' }),
      link: `/${clinic}`,
    },
    { ...settingMenu(f, clinic)[0], icon: <SettingIcon /> },
  ];

  return (
    <React.Fragment>
      <NavbarWrapper className='l-navbar'>
        <NavbarDetail>
          {
            isTabletAndMobile && <>
              {visible ? (
                <MenuButton icon={<CloseIcon />} onClick={drawerHandler} />
              ) : (
                <MenuButton icon={<MenuIcon />} onClick={() => setVisible(true)} />
              )}
            </>
          }
          <HomeIconLink to={`/${ws?._id}`}>
            <img src={logo} alt='dentall Lab' width='175' height='28' />
          </HomeIconLink>
          <NavSettingAndAuth>
            <DesktopOrLaptop>
              <Tooltip placement='bottom' title={f({ id: 'setting' })} justify='center'>
                <StyledNavLink to={`/${ws?._id}/setting/lab`} $setting>
                  <img src={setting} alt='setting' width='20' height='20' />
                </StyledNavLink>
              </Tooltip>
            </DesktopOrLaptop>
            <Authentication
              ws={ws}
              member={member}
              currentUser={currentUser}
              role={ws?.owner_id === currentUser._id ? '管理員' : '一般使用者'}
              accountHandler={() => SSORedirectUtil.redirectHref(`${wsUrl}/${ws?._id}`)}
              logoutHandler={() =>
                SocketUtil.onLogoutAll({ user_id: currentUser?._id, uniNum: currentUser?.erpUniqueNumber })
              }
            />
          </NavSettingAndAuth>
        </NavbarDetail>
      </NavbarWrapper>
      {
        isTabletAndMobile && <>
          <MobileNavStyle />
          <Drawer
            placement='left'
            onClose={drawerHandler}
            visible={visible}
            width={'100%'}
            closable={false}
            className='nav__drawer'
            mask={false}
          >
            <PanelMenu
              menu={mobileMenu(f, ws?._id)}
              showIcon={true}
              closeHandler={drawerHandler}
              className={'nav__menu'}
            />
          </Drawer>
        </>
      }
    </React.Fragment>
  );
};

export default Navbar;
