import _ from 'lodash';
import appendQuery from 'append-query';
import { cas_login_url, cas_logout_url, cas_switch_auth_url } from '../../../config';

export default class SSORedirectUtil {
  static getHref() {
    return window.location.href;
  }

  static redirectLanding() {
    window.history.replaceState(null, '', '/');
  }

  static redirectHref(href) {
    if (_.isEmpty(href)) {
      window.location.href = '/';
    } else {
      window.location.href = href;
    }
  }

  static redirectCasLoginPath(useRecentUrl = true) {
    if (useRecentUrl) {
      return this.redirectHref(appendQuery(cas_login_url, { redirect_url: this.getHref() }));
    }
    return this.redirectHref();
  }

  static redirectCasSwitchAuthPath() {
    this.redirectHref(appendQuery(cas_switch_auth_url, { redirect_url: this.getHref() }));
  }

  static redirectCasLogoutPath(enSctId, uniNum) {
    this.redirectHref(appendQuery(cas_logout_url, { redirect_url: `${window.location.origin}`, enSctId, uniNum }));
  }
}
