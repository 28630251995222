import _ from 'lodash';
import Cookies from 'js-cookie';
import { session_domain } from '@/config';

export function saveSuccessTokenId(SCT_ID, exp = 30) {
  return Cookies.set('SCT_ID', SCT_ID, { expires: exp, domain: session_domain });
}

export function saveEnSctId(enSctId) {
  return Cookies.set('enSctId', enSctId, { expires: 30, domain: session_domain });
}

export function getEnSctId() {
  return Cookies.get('enSctId', { domain: session_domain });
}

export function removeEnSctId() {
  return Cookies.remove('enSctId', { domain: session_domain });
}

export function removeSTD() {
  return Cookies.remove('STD', { domain: session_domain });
}

export function saveSuccessTokenData(SCT_ID, uniNum, enSctId) {
  let getExistsStd = Cookies.get('STD', { domain: session_domain });
  try {
    if (JSON.parse(getExistsStd)) {
      getExistsStd = JSON.parse(getExistsStd);
    } else {
      getExistsStd = {};
    }
  } catch (e) {
    getExistsStd = {};
  }

  const stdObj = !_.isEmpty(getExistsStd)
    ? { ...getExistsStd, [uniNum]: { SCT_ID, enSctId } }
    : { [uniNum]: { SCT_ID, enSctId } };

  return Cookies.set('STD', JSON.stringify(stdObj), { expires: 30, domain: session_domain });
}

export function getSuccessTokenData() {
  return Cookies.get('STD', { domain: session_domain });
}

export function getSuccessTokenId() {
  return Cookies.get('SCT_ID', { domain: session_domain });
}

export function removeSuccessTokenId() {
  return Cookies.remove('SCT_ID', { domain: session_domain });
}

export function removeSuccessTokenDataWithId(uniNum) {
  let getExistsStd = Cookies.get('STD', { domain: session_domain });
  try {
    if (JSON.parse(getExistsStd)) {
      getExistsStd = JSON.parse(getExistsStd);
      getExistsStd[uniNum] = undefined;
    } else {
      getExistsStd = {};
    }
  } catch (e) {
    getExistsStd = {};
  }
  return Cookies.set('STD', JSON.stringify(getExistsStd), { expires: 30, domain: session_domain });
}

export function removeSuccessTokenDataWithSctId(SCT_ID) {
  let getExistsStd = Cookies.get('STD', { domain: session_domain });
  try {
    if (JSON.parse(getExistsStd)) {
      getExistsStd = JSON.parse(getExistsStd);
    } else {
      getExistsStd = {};
    }
    if (_.isEmpty(getExistsStd)) {
      return Cookies.remove('STD', { domain: session_domain });
    } else {
      for (const [key, { SCT_ID: value }] of Object.entries(getExistsStd)) {
        if (value === SCT_ID) {
          getExistsStd[key] = undefined;
        }
      }
      return Cookies.set('STD', JSON.stringify(getExistsStd), { expires: 30, domain: session_domain });
    }
  } catch (e) {
    return Cookies.remove('STD', { domain: session_domain });
  }
}

export const removeAuthTokens = async SCT_ID => {
  await removeSuccessTokenId();
  await removeSuccessTokenDataWithSctId(SCT_ID);
  await removeEnSctId();
  await removeSTD();
  await removeWorkspaceId();
};

export const getWorkspaceId = () => {
  return Cookies.get('wsId', { domain: session_domain });
};

export function removeWorkspaceId() {
  return Cookies.remove('wsId', { domain: session_domain });
}

export const saveWorkspaceId = (wsId, exp = 30) => {
  return Cookies.set('wsId', wsId, { expires: exp, domain: session_domain });
};