import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import socketUtil from '@/utils/socketUtil';

// region
const Container = styled.div`
  display: grid;
  place-content: center;

  > div:nth-child(1) {
    color: var(--default-500-hint);
    font-size: 14px;
  }
  > div:nth-child(2) {
    margin-top: 10px;
    font-size: 14px;
    text-align: center;
    color: var(--default-500-primary);

    &:hover {
      cursor: pointer;
      color: var(--default-500-primary);
    }
    &:focus {
      color: var(--default-500-primary);
    }
    &:active {
      color: var(--default-500-primary);
    }
  }
`;
// endregion

const Logout = ({ currentUser = {}, isClinicAccount = false, accountInfo = {} }) => {
  const { formatMessage: f } = useIntl();

  return (
    <Container>
      <div>
        {isClinicAccount
          ? `${accountInfo?.accountName} (${accountInfo?.accountId})`
          : `${accountInfo?.accountName} (${
              !_.isEmpty(accountInfo?.accountEmail) ? accountInfo?.accountEmail : accountInfo?.accountPhone
            })`}
      </div>
      <div onClick={() => socketUtil.onLogoutAll({ user_id: currentUser?._id, uniNum: currentUser?.erpUniqueNumber })}>
        <span>{f({ id: 'navbar.logout' })}</span>
      </div>
    </Container>
  );
};

export default Logout;
