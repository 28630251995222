import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { HelmetProvider } from 'react-helmet-async';
import store, { history } from './store';
import Locale from './component/Locale';
import AuthRoutes from './component/Routes';
import SimpleReactLightbox from 'simple-react-lightbox';
import GA from './utils/GA';
import Spin from './component/Spin';

// TODO: to control prod and staging
GA.init('UA-199191257-1');

const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter history={history}>
        <HelmetProvider>
          <ConfigProvider autoInsertSpaceInButton={false}>
            <Locale>
              <SimpleReactLightbox>
                <Suspense fallback={<Spin />}>
                  <AuthRoutes />
                </Suspense>
              </SimpleReactLightbox>
            </Locale>
          </ConfigProvider>
        </HelmetProvider>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
