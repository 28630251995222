import { cas_api_url } from '../config';
import axios from 'axios';

const requestURL = `${cas_api_url}/auth`;
export default class SSOAuthentication {
  static postStd = (SCT_ID, getStd) => {
    return axios({
      url: `${requestURL}/fetchStd`,
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      data: { SCT_ID, sctData: getStd },
    })
      .then(({ data }) => data)
      .catch(err => err);
  };

  static getSctId = async ST => {
    return axios({
      url: `${requestURL}/stVerify?ST=${ST}`,
      method: 'GET',
      headers: { 'content-type': 'application/json' },
    })
      .then(({ data }) => data)
      .catch(err => err);
  };

  static getUser = async SCT_ID => {
    return axios({
      url: `${requestURL}/sctIdVerify?sctId=${SCT_ID}`,
      method: 'GET',
      headers: { 'content-type': 'application/json' },
    })
      .then(({ data }) => data)
      .catch(err => err);
  };
}
