import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { createSelector } from '@reduxjs/toolkit';
import { appInit } from './utils/appInit';
import { getSuccessTokenData } from '@/utils/token';
import SocketUtil from '../../utils/socketUtil';

const authReSelector = createSelector(
  state => state.homePageReducer.authentication,
  ({ is_ok, currentUser, appInit }) => ({
    is_ok,
    isFetching: appInit.app_init,
    currentUser,
  }),
);

export const useAuth = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const ST = searchParams.get('ST') || '';
  const getStd = getSuccessTokenData();

  useEffect(() => {
    dispatch(appInit({ ST, navigate }));
  }, [dispatch, ST, navigate]);

  useEffect(() => {
    dispatch(SocketUtil.fetchStd(getStd));
  }, [dispatch, getStd]);

  return useSelector(authReSelector);
};
