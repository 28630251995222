export const apiHeadersType = {
  jsonType: 'application/json',
};
export const api_url = process.env.REACT_APP_SELF_API_URL || '';
export const cas_api_url = process.env.REACT_APP_CAS_API_URL || '';
export const cas_login_url = process.env.REACT_APP_CAS_LOGIN_URL || '';
export const cas_switch_auth_url = process.env.REACT_APP_CAS_SWITCH_AUTH_URL || '';
export const cas_logout_url = process.env.REACT_APP_CAS_LOGOUT_URL || '';
export const myClinicAccountLink = process.env.REACT_APP_CAS_MYACCOUNT_URL || '';
export const myAccountLink = process.env.REACT_APP_WWW_MYACCOUNT_URL || '';
export const img_url = 'https://storage.googleapis.com/dentaltw-storage/images';
export const shareUrl = process.env.REACT_APP_WS_URL || '';
export const url = process.env.REACT_APP_SELF_URL || window.location.origin;
export const salt = '883e!0f0bb';
export const secret = '2d39660e3217025a32a0d211668cab99';
export const algolia = '9R0ZIGNEBL';
export const landingPageUrl = 'https://dentallio.github.io/baru-landing';
export const session_domain = process.env.REACT_APP_SESSION_DOMAIN || '';
export const wsSubsystemApiUrl = process.env.REACT_APP_WS_SUBSYSTEM_API_URL || '';
export const wsUrl = process.env.REACT_APP_SHEETA_URL || '';
